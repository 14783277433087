import fetchWithTimeout from "../fetchWithTimeout";

const API_URL = "https://apidev.dgsign.id/ori/";

const apiRequest = async (
  method = "post",
  endpoint,
  param,
  timeout = 60000
) => {
  try {
    let requestBody;

    requestBody = {
      method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(param), // body data type must match "Content-Type" header
    };

    const response = await fetchWithTimeout(
      API_URL + endpoint,
      requestBody,
      timeout
    );
    return response.json();
  } catch (error) {
    // Timeouts if the request takes
    // longer than x seconds

    return { status: false, label: "", message: "gangguan koneksi" };
  }
};

export default apiRequest;
